import React, { useState } from "react";
import useDarkMode from "use-dark-mode";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import Link from "@material-ui/core/Link";
import { grey } from "@material-ui/core/colors";

const MyApp: React.FC = () => {
  const darkMode = useDarkMode();
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          description
          logoUrl
        }
      }
    }
  `);

  return (
    <>
      <Grid container alignContent="center" alignItems="center" justify="center" direction="column">
        <Link
          component={(props: { children: any }) => (
            <GatsbyLink to={"/page-2"}>
              {props.children}
            </GatsbyLink>
          )}>
        </Link>
        <p style={{ textAlign: "center", marginBottom: "90px" }}>
          <img className="logo" alt="logo" src={data.site.siteMetadata.logoUrl} style={{ paddingTop: "5px", paddingBottom: "20px" }} />
          <Typography gutterBottom>{data.site.siteMetadata.description}</Typography>
          <Typography gutterBottom style={{ paddingBottom: "30px" }}>The goal of the effort is to explore, improve, and expand the service operator, developer, and consumer uses on behalf of all users.</Typography>
          <Button variant="contained" color="primary" href="mailto:xops.net@protonmail.com">Get In Touch</Button>
        </p>
      </Grid>
      <Grid container justify="space-between" style={{ marginBottom: "125px" }}>
        <Grid item container direction="column" style={{ width: "230px" }}>
          <Link
            component={(props: { children: any }) => (
              <GatsbyLink to={"#"} style={{ textDecoration: "none", color: grey[500] }} activeStyle={{ color: grey[500] }}>
                {props.children}
              </GatsbyLink>
            )}>
            <Grid>
              <Box>
                <Typography variant="h6" gutterBottom color="primary">🌐 Distributed Systems</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Resolve engineering issues and build distributed networks</Typography>
              </Box>
            </Grid>
          </Link>
        </Grid>
        <Grid item container direction="column" style={{ width: "230px" }}>
          <Link
            component={(props: { children: any }) => (
              <GatsbyLink
                to={"#"}
                style={{ textDecoration: "none", color: grey[500] }}
                activeStyle={{ color: grey[500] }}
              >
                {props.children}
              </GatsbyLink>
            )}>
            <Grid>
              <Box>
                <Typography variant="h6" gutterBottom color="primary">🛠 Tooling and Integrations</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Provide reach and consistency.</Typography>
              </Box>
            </Grid>
          </Link>
        </Grid>
        <Grid item container direction="column" style={{ width: "230px" }}>
          <Link
            component={(props: { children: any }) => (
              <GatsbyLink to={"#"}
                style={{ textDecoration: "none", color: grey[500] }}
                activeStyle={{ color: grey[500] }}
              >
                {props.children}
              </GatsbyLink>
            )}>
            <Grid>
              <Box>
                <Typography variant="h6" gutterBottom color="primary">💘 DevRel</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Build relationships with the developer community.</Typography>
              </Box>
            </Grid>
          </Link>
        </Grid>
      </Grid>

      <Grid container justify="center">
        <Typography gutterBottom variant="h6" style={{marginBottom: "15px"}}>Current and Former Clients:</Typography>
      </Grid>
      <Grid container justify="center">
        <a href="https://pressreader.com"><img height="100px" src="https://user-images.githubusercontent.com/364566/73622226-b4234f00-45ed-11ea-8b59-29634612badb.png" /></a>
        <a href="https://www.fnx.tech/"><img height="100px" src="https://avatars1.githubusercontent.com/u/48633247?s=200&v=4" /></a>
        <a href="https://emeraldpay.io/"><img height="100px" src="https://user-images.githubusercontent.com/364566/61559811-6a8f6000-aa1f-11e9-9ad4-ba22c252c812.png" /></a>
        <img height="100px" src="https://user-images.githubusercontent.com/364566/73622209-a66dc980-45ed-11ea-93a6-8d3cf26b56fa.png" />
        <a href="https://etclabs.org"><img height="100px" src="https://user-images.githubusercontent.com/364566/73622148-73c3d100-45ed-11ea-8b11-afdf2231ec02.png" /></a>
      </Grid>
    </>
  );
};

export default MyApp;
